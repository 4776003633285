/* Custom Profile Dropdown Styles */
.custom-profile-nav {
    margin-left: 1rem;
    position: relative;
  }
  
  .custom-profile-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  .custom-profile-header {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }
  
  .custom-profile-header:hover {
    background-color: rgba(158, 203, 94, 0.1);
  }
  
  .custom-profile-media {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
  }
  
  .custom-profile-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .custom-profile-info {
    margin-right: 8px;
  }
  
  .custom-profile-name {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  
  .custom-profile-email {
    color: #666;
    font-size: 12px;
    margin: 0;
  }
  
  /* Dropdown Menu Styles */
  .custom-profile-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    width: 280px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #eee;
    margin-top: -4px;
    z-index: 1000;
  }
  
  .custom-dropdown-header {
    padding: 16px;
    background-color: rgba(158, 203, 94, 0.1);
    border-bottom: 1px solid rgba(158, 203, 94, 0.2);
  }
  
  .custom-dropdown-profile {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .custom-dropdown-profile-img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .custom-dropdown-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .custom-dropdown-menu-item {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    transition: background-color 0.2s ease;
  }
  
  .custom-dropdown-menu-item:hover {
    background-color: rgba(158, 203, 94, 0.1);
  }
  
  .custom-dropdown-menu-item svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    stroke: #9ecb5e;
  }
  
  .custom-dropdown-menu-item span {
    font-size: 14px;
  }
  
  .custom-dropdown-logout {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .custom-dropdown-logout:hover {
    background-color: #fff5f5;
  }
  
  .custom-dropdown-logout svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    stroke: #ff5757;
  }
  
  .custom-dropdown-logout span {
    color: #ff5757;
    font-size: 14px;
  }
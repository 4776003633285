.merchant-modal .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .merchant-modal .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 0;
  }
  
  .modal-title {
    color: #1890ff !important;
    margin-bottom: 24px !important;
    font-weight: 900 !important;
  }
  
  .modal-image-container {
    width: 100%;
    margin-bottom: 24px;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .modal-footer-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
    background: #fff;
  }
  
  .transaction-text {
    color: #52c41a;
    font-weight: 700;
    margin: 0;
  }
  
  .custom-close-icon {
    font-size: 20px;
    color: #999;
    transition: color 0.3s;
  }
  
  .custom-close-icon:hover {
    color: #666;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .modal-footer-custom {
      flex-direction: column;
      gap: 16px;
    }
  
    .transaction-text {
      text-align: center;
    }
  }
.dashboard-container {
  padding: 2rem;
  background: #f8fafc;
  min-height: 30vh;
}

.dashboard-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
}

.dashboard-tab {
  padding: 0.75rem 1.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: #64748b;
  background: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dashboard-tab:hover {
  color: #1e293b;
  background: #f1f5f9;
}

.dashboard-tab.active {
  color: #ffffff;
  background: #3b82f6;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2);
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  animation: fadeIn 0.5s ease-out;
}

.dashboard-card {
  border-radius: 16px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.dashboard-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  z-index: 1;
}

.card-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.card-icon-wrapper {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon {
  width: 24px;
  height: 24px;
  color: white;
}

.card-info {
  flex: 1;
}

.card-value {
  font-size: 1.75rem;
  font-weight: 700;
  color: white;
  margin: 0;
  line-height: 1.2;
}

.card-label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.95rem;
  margin: 0.25rem 0 0 0;
}

.dashboard-empty {
  text-align: center;
  padding: 2rem;
  color: #64748b;
  font-size: 1rem;
}

/* Gradient Classes */
.card-gradient-blue {
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  box-shadow: 0 8px 16px -4px rgba(59, 130, 246, 0.3);
}

.card-gradient-green {
  background: linear-gradient(135deg, #10b981 0%, #059669 100%);
  box-shadow: 0 8px 16px -4px rgba(16, 185, 129, 0.3);
}

.card-gradient-purple {
  background: linear-gradient(135deg, #8b5cf6 0%, #7c3aed 100%);
  box-shadow: 0 8px 16px -4px rgba(139, 92, 246, 0.3);
}

.card-gradient-orange {
  background: linear-gradient(135deg, #f97316 0%, #ea580c 100%);
  box-shadow: 0 8px 16px -4px rgba(249, 115, 22, 0.3);
}

.card-gradient-pink {
  background: linear-gradient(135deg, #ec4899 0%, #db2777 100%);
  box-shadow: 0 8px 16px -4px rgba(236, 72, 153, 0.3);
}

.card-gradient-teal {
  background: linear-gradient(135deg, #14b8a6 0%, #0d9488 100%);
  box-shadow: 0 8px 16px -4px rgba(20, 184, 166, 0.3);
}

.card-gradient-red {
  background: linear-gradient(135deg, #ef4444 0%, #dc2626 100%);
  box-shadow: 0 8px 16px -4px rgba(239, 68, 68, 0.3);
}

/* Hover Effects */
.dashboard-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px -8px rgba(0, 0, 0, 0.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 1rem;
  }

  .dashboard-tabs {
    gap: 0.5rem;
  }

  .dashboard-tab {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .card-value {
    font-size: 1.5rem;
  }
}

/* Print Styles */
@media print {
  .dashboard-container {
    background: white;
  }

  .dashboard-card {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.play-btn {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 40px;
  border-color: transparent transparent transparent #ffffff;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.play-btn:hover {
  opacity: 1;
}
.dmt-modal .modal-dialog {
    max-width: 500px;
  }
  
  .dmt-modal .modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .dmt-modal-header {
    background-color: #007bff;
    color: white;
    padding: 15px 20px;
  }
  
  .dmt-modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding: 20px;
  }
  
  .dmt-transaction-details {
    font-size: 0.9rem;
  }
  
  .dmt-card {
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 15px;
  }
  
  .dmt-card-header {
    padding: 10px 15px;
    font-weight: bold;
  }
  
  .dmt-card-body {
    padding: 15px;
  }
  
  .dmt-card-body p {
    margin-bottom: 8px;
  }
  
  .dmt-bg-primary { background-color: #007bff; color: white; }
  .dmt-bg-success { background-color: #28a745; color: white; }
  .dmt-bg-warning { background-color: #ffc107; color: black; }
  .dmt-bg-info { background-color: #17a2b8; color: white; }
  .dmt-bg-secondary { background-color: #6c757d; color: white; }
  .dmt-bg-danger { background-color: #dc3545; color: white; }
  
  .dmt-badge {
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 0.75rem;
    margin-left: 5px;
  }
  
  @media (max-width: 576px) {
    .dmt-modal .modal-dialog {
      margin: 10px;
    }
  }
/* Table Styles */
.custom-table .ant-table-thead > tr > th {
    background-color: #f8f9fa;
    font-weight: 600;
  }
  
  .custom-table .ant-table-tbody > tr:hover > td {
    background-color: #f8f9fa;
  }
  
  .custom-table .ant-table-cell {
    padding: 12px 16px !important;
  }
  
  /* Form Select Styles */
  .form-select.border-success {
    border-color: #28a745;
    background-color: #f8fff9;
  }
  
  .form-select.border-danger {
    border-color: #dc3545;
    background-color: #fff8f8;
  }
  
  /* Dropdown Menu Styles */
  .ant-dropdown-menu {
    padding: 4px 0;
  }
  
  .ant-dropdown-menu-item {
    padding: 8px 12px;
  }
  
  /* Pagination Styles */
  .ant-pagination {
    margin-bottom: 0;
  }
  
  /* Button Styles */
  .btn-action,
  .btn-report {
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
  }
  
  .btn-action:hover,
  .btn-report:hover {
    background-color: #e6e6e6;
    border-color: #d9d9d9;
  }
  
  /* Dropdown Link Styles */
  .ant-dropdown-menu-item a {
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
    display: block;
  }
  
  .ant-dropdown-menu-item a:hover {
    color: #1890ff;
  }
  
  /* Table Cell Spacing */
  .custom-table .ant-table-cell .d-flex {
    gap: 8px;
  }